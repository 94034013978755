<template>
    <v-container class="mt-8">
        <v-row justify="center">
            <v-col md="8">
                <v-card flat outlined>
                    <v-row>
                        <v-col class="pb-4 mx-4" md="8">
                            <AutoCompleteField class="py-2" :label="$t('folder name')" outlined dense v-model="folder_id"
                                endPoint="/accounting/folder/auto-complete" @input="onFolderInput" /> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0 mx-4" md="4">
                            <AutoCompleteField class="pb-5" :label="$t('account name')" outlined dense v-model="Account"
                                returnObject endPoint="/accounting/account/auto-complete" @input="onAccountInput" />
                        </v-col>
                    </v-row>
                    <v-row justify="start" class="ma-0 mt-2">
                        <v-col>
                            <div style="overflow: auto; max-height: 500px;">
                                <v-data-table :dark="$store.state.isDarkMode" :key="tableKey1" :headers="headers" :items="names"
                                    item-key="id" @click:row="filternames" hide-default-footer disable-pagination style="border: 1px solid #ccc;
                                    border-radius: 4px; cursor: pointer;" :loading="loading">
                                    <template v-slot:header.actions="{ headers }">
                                        <v-icon @click="convertSelectAll">mdi-swap-horizontal</v-icon>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-col>
                        <v-col>
                            <div style="overflow: auto; max-height: 500px;">
                                <v-data-table :dark="$store.state.isDarkMode" :key="tableKey2" :headers="selectedHeaders"
                                    :items="selectedNames" item-key="id" @click:row="RemoveSelectedItem" hide-default-footer disable-pagination
                                    style="border: 1px solid #ccc; border-radius: 4px;  cursor: pointer;" :loading="loading">
                                    <template v-slot:header.actions="{ selectedHeaders }">
                                        <v-icon @click="convertNotSelectAll">mdi-swap-horizontal</v-icon>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
                <v-row justify="center" class="mt-6">
                    <v-col cols="2">
                        <v-btn :loading="loadingbtn" color="green" class="save-button" @click="submit()">{{
                            $t("save")
                        }}</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from "axios";
import i18n from '@/i18n';
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
export default {
    components: {
        AutoCompleteField,
    },
    data() {
        return {
            tableKey1: 0,
            tableKey2: 0,
            folder_id: null,
            Account: null,
            roleId: this.$route.params.id,
            headers: [{ text: i18n.t("All Accounts"), value: "name" }, { text: 'Actions', value: 'actions', sortable: false }],
            selectedHeaders: [{ text: i18n.t("Role Account"), value: "name" }, { text: 'Actions', value: 'actions', sortable: false }],
            names: [],
            selectedNames: [],
            abilities: [],
            loading: false,
            loadingbtn: false,
        };
    },
    methods: {
        async onFolderInput() {
            this.loading = true;
            await axios.get(`/ability/accounting/${this.roleId}/${this.folder_id}`).then((res) => {
                if (res.status == 200) {
                    this.names = res.data.data.folder;
                    this.selectedNames = res.data.data.role;
                }
            });
            this.loading = false;
        },
        convertSelectAll() {
            for (let i = 0; i < this.names.length; i++) {
                this.selectedNames.push(this.names[i]);
            }
            this.names = [];
        },
        convertNotSelectAll() {
            for (let i = 0; i < this.selectedNames.length; i++) {
                this.names.push(this.selectedNames[i]);
            }
            this.selectedNames = [];
        },
        onAccountInput() {
            for (let i = 0; i < this.names.length; i++) {
                if (this.names[i].name == this.Account.name) {

                    let temp = this.names[i];
                    this.names[i] = this.names[0];
                    this.names[0] = temp;
                    this.tableKey1 += 1;
                    break;
                }
            }
            for (let i = 0; i < this.selectedNames.length; i++) {
                if (this.selectedNames[i].name == this.Account.name) {
                    let temp = this.selectedNames[i];
                    this.selectedNames[i] = this.selectedNames[0];
                    this.selectedNames[0] = temp;
                    this.tableKey2 += 1;
                    break;
                }
            }
        },
        filternames(item) {
            for (let i = 0; i < this.names.length; i++) {
                if (this.names[i].name == item.name) {
                    this.names.splice(i, 1);
                }
            }
            this.selectedNames.push(item);
            console.log(this.selectedNames)
        },
        RemoveSelectedItem(item) {
            for (let i = 0; i < this.selectedNames.length; i++) {
                if (this.selectedNames[i] == item) {
                    this.selectedNames.splice(i, 1);
                }
            }
            this.names.push(item);
        },
        async submit() {
            this.loadingbtn = true;
            for (let i = 0; i < this.selectedNames.length; i++) {
                this.abilities.push(this.selectedNames[i].id);
            }
            await axios
                .post(`/role/accounting-ability`, {
                    folder_id: this.folder_id,
                    abilities: this.abilities,
                    role_id: this.roleId
                })
                .then((res) => {
                    if (res.status == 200) {
                        this.$Notifications(
                            "تم  الحفظ بنجاح",
                            { rtl: true, timeout: 2500 },
                            "success"
                        );
                    }
                    this.loadingbtn = false;
                });
            this.onFolderInput();
        },
    },
};
</script>